body {
  margin: 0;
  padding: 0;
  /* font-family: 'Gill Sans', 'Gill Sans MT'; */
}

a {
  text-decoration: none !important;
  color: #04A0D2
}

.dividing {
  width: 100%;
  height: 10px;
  background-color: rgb(226, 226, 229);
}

.bg-dark {
  background: rgb(14, 13, 13) !important;
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}

.station-section {
  max-width: 100%;
  height: auto;
  background-color: #fff;
  padding: 20px 3.2vmax 28px 3.2vmax;
  text-align: center;
}

.station-header {
  padding: 10px 8vmax 10px 8vmax;
  font-size: 20px;
  color: rgba(245,245,247);
  max-width: 1250px;
  margin: 10px auto;
  text-align: center;
}

.station-header h1 {
  margin-bottom: 15px;
  font-size: 45px;
}

.station-header p {
  font-weight: 200;
}

.building-header {
  padding: 60px 11vmax 0px 11vmax;
  color: rgba(29, 29, 31);
  font-size: 20px;
  max-width: 1250px;
  margin: 0px auto;
  text-align: center;
}

.building-header h1 {
  margin-bottom: 15px;
  font-size: 45px;
}

.building-header p {
  font-weight: 200;
}

.station-render-section {
  width: 100%;
  height: auto;
  background-color: #1D1D1F;
  padding: 4rem;
}

.jumbotron {
  width: 100%;
  height: auto;
  color: #fff;
  /* padding: 85px 0 0 0; */
  margin-bottom: 0px;
  padding-top: 0;
}

.heading {
  padding: 10px;
  text-align: center;
}

.container {
  width: 80%;
  max-width: 1200px;
  padding: 40px 0px;
  text-align: center;
  margin: auto;
  position: static;
}

.expand-button {
  background: transparent;
  position: relative;
  width: 24px;
  height: 24px;
  border: 2px solid transparent;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb05 1.5s infinite;
  animation: sdb05 1.5s infinite;
  padding: 3px;
}

@-webkit-keyframes sdb05 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg) translate(-6px, 6px);
    opacity: 0;
  }
}

@keyframes sdb05 {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-45deg) translate(-6px, 6px);
    opacity: 0;
  }
}

#collapse-text {
  font-size: 20px;
  padding-top: 35px;
  max-width: 1200px;
  margin: 0px auto;
}

.building-overview-section {
  height: auto;
  max-width: 1680px;
  padding: 38px 0;
  margin: auto;
}

.building-overview {
  background-image: url(../images/building-overview.jpg);
  height: 30vw;
  width: 85vw;
  max-width: 1360px;
  max-height: 480px;
  background-size: contain;
  background-repeat: no-repeat;
  position: static;
  margin-left: auto;
  margin-right: auto;
}

.building-overview-header {
  margin: 0 auto;
  width: 80%;
  padding: 50px 30px;
  box-sizing: border-box;
  background-color: none;
  text-align: center;
  color: #1D1D1F;
}

.building-overview-header h2 {
  font-size: 35px;
  margin: 0;
  padding: 0;
}

.building-overview-header p {
  font-size: 20px;
  margin: 12px 0 0;
  font-weight: 200;
}


#building-overview-btn-1 {
  left: 35%;
  top: 57%;
  position: relative;
  width: 35px;
  height: 35px;
  background-image: url(../images/camera-icon.png);
  border-radius: 100%;
  background-size: cover;
}

#building-overview-btn-2 {
  left: 62%;
  top: 40%;
  position: relative;
  width: 35px;
  height: 35px;
  background-image: url(../images/camera-icon.png);
  border-radius: 100%;
  background-size: cover;
}

img {max-width:100%; margin-bottom: 20px; margin-top: 20px;}

.station1 {
  background-image: url(../images/station.jpg);
  height: 55vw;
  width: 75vw;
  max-width: 1200px;
  max-height: 800px;
  background-size: contain;
  background-repeat: no-repeat;
  position: static;
  margin-left: auto;
  margin-right: auto;
}

.popover-btn {
  background-color: #fff;
  opacity: 0.9;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  background-image: url(../images/magnifier-icon.jpg);
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center;
}

#popover-btn1 {
  position: relative;
  top: 20%;
  left: -16%;
}

#popover-btn2 {
  position: relative;
  top: 48%;
  left: -12%;
}

#popover-btn3 {
  position: relative;
  top: 46%;
  left: 5%;
}

.station2-section {
  padding: 0px 40px 60px 40px;
  height: auto;
  width: 55vw;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.station2 {width: 100%; margin:auto 0; max-width: 1000px; max-height: 1000px;}

.render1-box {margin: auto; max-width: 1300px;}
.render2-box {margin: auto; max-width: 1300px; text-align: right;}
.render1 {width:70%; max-width: 942.89px; max-height: 513.55px;}
.render-1-description {width:30%; max-width: 390px; display: inline-block; text-align: center; color: rgb(245, 245, 247); font-size: 25px;}
.render-2-description {width:40%; max-width: 390px; margin: 0; display: inline-block; text-align: center; color: rgb(245, 245, 247); font-size: 25px;}
.render2 {width:60%; max-width: 811.19px; max-height: 333.97px; display: inline-block}

.modular-structure-section {
  height: auto;
  max-width: 100%;
  padding: 30px 0;
}

.modular-structure {
  background-image: url(../images/prefab-building.jpg);
  height: 60vw;
  width: 95vw;
  max-width: 1480px;
  max-height: 940px;
  background-size: contain;
  background-repeat: no-repeat;
  position: static;
  margin-left: auto;
  margin-right: auto;
}

#modular-structure-btn-1 {
  left: 61%;
  top: 30%;
  position: relative;
  width: 30px;
  height: 30px;
  background-image: url(../images/camera-icon.png);
  border-radius: 100%;
  background-size: cover;
}

#modular-structure-btn-2 {
  left: 55%;
  top: 18%;
  position: relative;
  width: 30px;
  height: 30px;
  background-image: url(../images/camera-icon.png);
  border-radius: 100%;
  background-size: cover;
}


.description-header {
  width: 90vw;
  padding: 80px 50px;
  box-sizing: border-box;
  background-color: none;
  text-align: center;
  color: #1D1D1F;
  /* margin-bottom: 100vmax; */
  margin: 0 auto 100vmax;
}

.description-header h2 {
  font-size: 35px;
  margin: 0;
  padding: 0;
}

.description-header p {
  font-size: 20px;
  margin: 12px 0 0;
  font-weight: 200;
}

.hs-description {
  width: 90vw;
  padding: 80px 0px;
  box-sizing: border-box;
  text-align: right;
  margin: 0 auto 115vmax;
  padding-right: 20vw;
}

.hs-description h2 {
  font-size: 32px;
  margin: 0;
  padding: 0;
}

.hs-description p {
  font-size: 18px;
  margin: 12px 0 0;
  font-weight: 200;
}

.BB-container {
  width: 100%;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: -1;
}

.BB {
  width: 82vw;
  max-width: 1450px;
  height: 60vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.BB div {
  max-width: 1450px;
  width: inherit;
  height: inherit;
  background-size: contain;
  background-position: center center;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
}

.all {
  background-image: url(../images/All.jpg);
}

.part-1 {
  background-image: url(../images/Layer1.jpg);
  opacity: var(--part-1);
}

.part-2 {
  background-image: url(../images/Layer2.jpg);
  opacity: var(--part-2);
}

.part-3 {
  background-image: url(../images/Layer3.jpg);
  opacity: var(--part-3);
}

.part-4 {
  background-image: url(../images/Layer4.jpg);
  opacity: var(--part-4);
}

.part-5 {
  background-image: url(../images/Layer5.jpg);
  opacity: var(--part-5);
}

.ending {
  background-image: url(../images/All.jpg);
  opacity: var(--ending);
}

.description-container {
  padding-bottom: 100vh;
  margin-top: -100vh;
}

.description:last-child {
  margin-bottom: 0;
}

.mapboxgl-popup {
  max-width: 30vmax !important;
  font-family: Arial, Helvetica, sans-serif;
}

.mapboxgl-popup-content {
  font-family: Arial, Helvetica, sans-serif;
  padding: 30px !important;
  border-radius: 6px !important;
}

.project-location {
  background-image: url('../images/red-star.png');
  background-size: cover;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.light-concrete {
  background-image: url('../images/light-concrete.png');
  background-size: cover;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.roofing {
  background-image: url('../images/roofing.png');
  background-size: cover;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.Skyrise-Prefab {
  background-image: url('../images/Skyrise-Prefab.png');
  background-size: cover;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.timber {
  background-image: url('../images/timber.png');
  background-size: cover;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.tube-steel {
  background-image: url('../images/tube-steel.png');
  background-size: cover;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.black-circle {
  background-color: #1D1D1F;
  background-size: cover;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  cursor: pointer;
}

footer {
  width: 100%;
  background-color: rgb(245, 245, 247);
  padding-bottom: 40px;
}

.footer {
  width: 100%;
  background-color: rgb(245, 245, 247);
  display: grid;
  grid-template-columns: 38% 30% 28%;
  gap: 2%;
  padding: 40px 50px;
}

.footer a {
  color: rgb(92, 90, 90);
  font-size: 20px;
}

.footer a:hover {
  color: rgb(58, 57, 57);
}

.footer-heading {
  opacity: 0.92;
  margin: 0px;
  align-self: center;
  text-align: center;
}

.footer-heading h2 {
  margin: 0;
}

.footer-pages {
  width: 275px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-self: center center;
  text-align: center;
}

.footer-pages a {
  margin: 0px 0px 20px;
}

.footer-pages a:nth-last-child(2) {
  margin: 0px;
}

.footer-pages a:last-child {
  margin: 0px;
}

.footer-contact {
  width: fit-content;
  display: flex;
  flex-direction: column;
  place-self: center;
}

.footer-contact a {
  margin: 0px 0px 20px;
}

@media screen and (max-width: 650px) { 

  .mapboxgl-popup-content {
    padding: 15px 15px 0 15px !important;
  } 

  .mapboxgl-popup-content h5 {
    font-size: 4.5vmin;
  }
  .mapboxgl-popup-content p {
    font-size: 3vmin;
  }  

  .description-header {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 120px;
  }

  .description-header h2 {
    font-size: 8vw !important;
    margin: 0;
    padding: 0;
  }
  
  .description-header p {
    font-size: 5vw !important;
    margin: 12px 0 0;
  }

  .building-overview-header {
    padding: 20px 20px 60px 20px;
    width: 100%;
  }

  .building-overview-header h2 {
    font-size: 8vw !important;
    margin: 0;
    padding: 0;
  }
  
  .building-overview-header p {
    font-size: 5vw !important;
    margin: 12px 0 0;
  }

  #building-overview-btn-1 {
    left: 45%;
    top: 50% !important;
    position: relative;
    width: 2.8vmax !important;
    height: 2.8vmax !important;
  }

  #building-overview-btn-2 {
    left: 58% !important;
    top: 38% !important;
    position: relative;
    width: 2.8vmax !important;
    height: 2.8vmax !important;
  }

  #modular-structure-btn-1 {
    left: 61%;
    top: 26%;
    position: relative;
    width: 2.8vmax !important;
    height: 2.8vmax !important;
  }
  
  #modular-structure-btn-2 {
    left: 50%;
    top: 14%;
    position: relative;
    width: 2.8vmax !important;
    height: 2.8vmax !important;
  }

  .station1 {max-width:100%}

  .station2 {max-width:100%}

}

@media screen and (max-width: 992px) { 
  .description-header h2 {
    font-size: 6vw;
    margin: 0;
    padding: 0;
  }
  
  .description-header p {
    font-size: 3vw;
    margin: 12px 0 0;
  }

  #building-overview-btn-1 {
    left: 36%;
    top: 53%;
    position: relative;
    width: 3vmax;
    height: 3vmax;
  }

  #building-overview-btn-2 {
    left: 61%;
    top: 40%;
    position: relative;
    width: 3vmax;
    height: 3vmax;
  }

  .station-header {
    padding: 0px 10px;
  }
  
  .building-header {
    padding: 0px 10px;
  }

  .render1 {width:100%}

  .render2 {width:100%}

  .render-1-description {width:100%; font-size: 20px;}
  .render-2-description {width:100%; font-size: 20px;}

  .station-render-section {
    padding: 40px 30px;
  }

  .footer {
    width: 100%;
    display: block;
    padding: 40px 20px;
  }
  
  .footer-heading {
    width: 80%;
    text-align: center;
    margin: 0 auto;
  }
  
  .footer-pages {
    width: 60%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-self: center end;
    text-align: center;
    margin: 50px auto;
  }
  
  .footer-contact {
    width: 100%;
    text-align: center;
  }

  .station2-section {
    padding: 20px 10px;
    height: auto;
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
  
}
